body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(185, 190, 205);
  min-height: 100vh;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  width: 100%;
  height: 100%;
}

circle {
  fill: rgb(68, 78, 124);
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.my-float {
  margin-top: 22px;
}

.label-container {
  position: fixed;
  bottom: 48px;
  right: 105px;
  display: table;
  visibility: hidden;
}

.label-text {
  color: #fff;
  background: rgba(51, 51, 51, 0.5);
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  border-radius: 3px;
}

.label-arrow {
  display: table-cell;
  vertical-align: middle;
  color: #333;
  opacity: 0.5;
}

.fixedBottomBar {
  position: absolute;
  bottom: 0;
  height: 0.5rem;
}

.sliderPadding {
  display: flex;
  width: 95%;
}

.loopCountLabel {
  position: absolute;
  right: 4em;
  padding-top: 0.5em;
  color: #6b6b6b;
}

.restartButton {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10em !important;
  width: 10em !important;
  border-radius: 50% !important;
}

.restartButton:hover {
  opacity: 0.5;
}

.xx-large-font {
  font-size: xx-large !important;
}

.breathLoopRemaining {
  position: absolute;
  top: 5em;
  right: 1.5em;
  font-weight:bold
}

.breathing-container {
  margin: 0 auto;
  height: 100%;
  position: absolute !important;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@media screen and (min-width: 1408px) {
  .breathing-container {
    max-width: 1050px;
  }
}

@media screen and (max-width: 1408px) {
  .breathing-container {
    max-width: 1150px;
  }
}

@media screen and (max-width: 1250px) {
  .breathing-container {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .breathing-container {
    max-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .breathing-container {
    max-width: 800px;
  }
}

.position-bottom {
  position: absolute;
  bottom: 2em;
  width: 100%
}